@import "~bootstrap/scss/bootstrap";

//App Style Variables
// Your variable overrides

body {
    background-color: transparent !important;
    // background-color: #2e2e2e;
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: .5px;
    font-family: 'Segoe UI';
    word-break: break-word;
    caret-color: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.modal-content {
    background-color: #3b3b3b;
}

// Scrollbar UI
.webkit-scroll {
    overflow: hidden;
}

.webkit-scroll:hover {
    overflow: overlay;
}

@supports (-ms-ime-align: auto) {
    .webkit-scroll {
        overflow: scroll !important;
    }
}

@supports (-moz-appearance:none) {
    .webkit-scroll {
        overflow: scroll !important;
    }
}

.webkit-scroll::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
}

.webkit-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

/* Handle */

.webkit-scroll::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.0);
}

.webkit-scroll::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 255, 255, 0.2);
}